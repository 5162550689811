/* @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Instrument+Serif:ital@0;1&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

@font-face{
  src: url(./fonts/OldStandard-Italic.ttf);
  font-family: OldStandard;
}

:root{
  --black : #000000;
  --white: #ffffff;
  --backgroundColour: #04060A;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: OldStandard; */
  font-family: "Playfair Display", serif;
  /* font-family: "Instrument Serif", serif; */
  font-weight: 400;
}


.container{
  position: relative;
  width: 100%;
  min-height: 100vh;

  /* padding: 10vh 0; */

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--black);
  /* background-color: var(--backgroundColour); */
}

